import React from "react"
import { css } from "@emotion/core"
import { h2 } from "css/primitives"
import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import ogImage from "images/seo/home.jpeg"
import { colors } from "css/theme"

const wrapper = css`
  padding-bottom: 100px;
  overflow: hidden;
  margin-top: -90px;
  background-color: ${colors.darkBackground};
  color: white;
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const wrapperTop = css`
  background-image: radial-gradient(
    circle at top,
    ${colors.background} 0%,
    black 100%
  );
  background-repeat: no-repeat;
  padding-top: 170px;
  padding-bottom: 100px;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 479px) {
    padding-top: 130px;
    padding-bottom: 80px;
  }
`

const heading = [
  h2,
  css`
    line-height: 1.14;
    font-size: 56px;
    color: white;
    text-align: center;
    margin-bottom: 1.5rem;
  `,
]

const headingText = css`
  color: white;
  opacity: 0.7;
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
  max-width: 670px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
`

const content = css`
  padding-top: 4rem;
  max-width: 830px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 21px;
  line-height: 34px;
  margin-left: auto;
  margin-right: auto;
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  @media (max-width: 767px) {
    font-size: 1rem;
  }
  a {
    color: ${colors.orange};
  }
  p {
    margin-bottom: 1.5rem;
  }
  li {
    margin-bottom: 1.5rem;
    line-height: 30px;
  }
  @media (max-width: 479px) {
    padding-top: 2.5rem;
  }
  h2,
  h3 {
    font-family: var(--heading);
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.78px;
    color: white;
    line-height: 1.4;
  }
  h2 {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
    @media (max-width: 767px) {
      font-size: 1.3rem;
    }
  }
  h3 {
    font-size: 1.35rem;
    margin-bottom: 1.25rem;
    @media (max-width: 767px) {
      font-size: 1.1rem;
    }
  }
  strong {
    color: white;
  }
`

const PrivacyPolicy = () => (
  <div>
    <SEO
      title="Smokescreen’s Privacy Policy"
      description="We are committed to keeping your personal information safe."
      image={ogImage}
    />
    <PageLayout options={{ headingBackground: false }}>
      <div css={wrapper}>
        <div css={wrapperTop}>
          <h1 css={heading}>Smokescreen’s Privacy Policy</h1>
          <p css={headingText}>
            We are committed to keeping your personal information safe
          </p>
        </div>
        <div css={content}>
          <p>
            Smokescreen’s Privacy Policy <strong>(the “Policy”)</strong>{" "}
            describes how Smokescreen, its affiliates and subsidiaries{" "}
            <strong>(“Smokescreen”, ”we”, “us”, “our”)</strong> collect, use,
            process, share, and safeguard personal information gathered through
            our website www.smokescreen.io <strong>(the “Website”)</strong>, our
            products and services, and via other means.
          </p>
          <p>
            This Policy applies to the activities for which Smokescreen is a
            “data controller” which means that Smokescreen decides why and how
            Personal Information is processed. Smokescreen may also act as a
            “data processor” (i.e., service provider to other companies), but
            this Policy does not apply to the processing of personal information
            we do on behalf of our customers. To learn more about the processing
            of your personal information in that context, please refer to the
            relevant customer’s privacy policy.
          </p>
          <h2>INFORMATION WE COLLECT</h2>
          <p>
            For the purpose of this Policy, “Personal Information” means any
            information relating to an identified or identifiable individual.{" "}
          </p>
          <p>
            Registration or usage of any of Smokescreen services implies your
            express consent and agreement to this Policy. Wherever applicable
            and required, we indicate whether and why you must provide us with
            your personal information. If you do not provide the requisite
            information when requested, you may not be able to fully benefit
            from our Website and/or our products and services, if that
            information is necessary to service your requirement or if we are
            legally required to collect it.
          </p>
          <p>
            We obtain Personal Information relating to you from various sources,
            more specifically described below:
          </p>
          <h3>Information Provided by You</h3>
          <ol>
            <li>
              <strong>Business contacts:</strong> We may receive professional
              contact details of employees and other individuals associated with
              our customers, partners, and vendors, such as first and last name,
              email address, phone number, title and department, and other
              information relevant to the particular business relationship.
            </li>
            <li>
              <strong>Communications:</strong> If you contact us directly (e.g.,
              via email, phone etc.) or fill out information on our website
              (e.g., to request a demo, to ask a question via our online contact
              form, to apply for a job on our career page etc.), we collect your
              name, email address, phone, company, postal address, the content,
              date and time of your message and any attachments thereto, and
              other information you may directly provide to us. If you post a
              public comment on our blog page, we ask you to provide your name
              and email address.
            </li>
          </ol>
          <h3>Information Collected via Cookies/Automated Means</h3>
          <p>
            The Smokescreen website may use “cookies” or similar technologies to
            help you personalize your online experience and improve our products
            and services. When we refer to “cookies” below, we mean cookies and
            similar technologies.
          </p>
          <ol>
            <li>
              <strong>Understanding cookies:</strong> A cookie is a text file
              that is placed on your hard disk by aweb page server. Cookies
              cannot be used to run programs or deliver viruses to your
              computer. Cookies are uniquely assigned to you and can only be
              read by a web server in the domain that issued the cookie to you.
            </li>
            <li>
              <strong>Need for us to use Cookies:</strong> One of the primary
              purposes of cookies is to provide a convenience feature to save
              you time. The purpose of a cookie is to tell the web server that
              you have returned to a specific page. For example, if you
              personalize Smokescreen pages, or register with Smokescreen’s
              website or services, a cookie helps Smokescreen to recall your
              specific information on subsequent visits. This simplifies the
              process of recording your Personal Information, such as billing
              addresses, shipping addresses, and so on. When you return to the
              same Smokescreen website, the information you previously provided
              can be retrieved, so you can easily use the Smokescreen features
              that you customized. Information collected via cookies is also
              used for the operation of the service, to maintain quality of the
              service, and to provide general statistics regarding use of the
              Smokescreen website.
            </li>
            <li>
              <strong>Your right to decline:</strong> You have the option to
              accept or decline cookies. Most web browsers automatically accept
              cookies, but you can usually modify your browser setting to
              decline cookies if you so prefer. If you choose to decline
              cookies, you may not be able to fully experience the interactive
              features of the Smokescreen services or websites you visit.
            </li>
            <li>
              <strong>Information collected via cookies:</strong> Information
              about your computer hardware and software, including your IP
              address, browser type, domain names, access times and referring
              website addresses.
            </li>
            <li>
              <strong>Cookies subject to change:</strong> The content and the
              information provide to you in this Policy is for your general
              information and use only. These cookies are subject to change
              without notice. You acknowledge that this information may contain
              inaccuracies or errors and is subject to change and we expressly
              exclude liability for any such inaccuracies or errors to the
              fullest extent permitted by law.
            </li>
          </ol>
          <h3>Information Collected via Third Parties</h3>
          <ol>
            <li>
              <strong>Smokescreen’s business customers:</strong> We may receive
              Personal Information about otherindividuals from our business
              customers to process it on their behalf as we provide them with
              our products and services. We process this information as
              instructed by our customers. To learn more about the processing of
              your Personal Information in that context, please refer to the
              relevant customer’s privacy policy.
            </li>
            <li>
              <strong>Social sign-on:</strong> We may collect Personal
              Information about you when you sign in via third party services
              (such as LinkedIn and Google). This information may include name,
              email address, IP address, phone number, etc.
            </li>
            <li>
              <strong>Other third parties:</strong> We may also obtain Personal
              Information about you from other third parties, such as database
              vendors, events, etc. This information may include name, job
              title, email address, phone number, social media profiles, company
              name, company address, etc.
            </li>
            <li>
              <strong>Publicly accessible sources:</strong> We may collect
              information about you, including name, job title, email address,
              phone number, social media profiles, company name, company
              address, etc. from publicly accessible sources, such as social
              media sites, web search, etc.
            </li>
          </ol>
          <h2>HOW WE USE YOUR PERSONAL INFORMATION</h2>
          <p>
            Smokescreen collects and uses your Personal Information as described
            below:
          </p>
          <ul>
            <li>
              <strong>Services:</strong> To operate, provide and maintain the
              services that we offer to our customers, our website, and other
              products and services that we may offer. For example, if you work
              at one of our customers, partners, or vendors, we may use your
              email address to manage the business relationship. Smokescreen
              does not sell, rent or lease its customer lists to third parties.
            </li>
            <li>
              <strong>Communications:</strong> We may send emails to an email
              address you provide to us (i) for customer service or
              technical-support purposes, or (ii) to send you information about
              topics or content that may interest you; updates about the latest
              developments or features of our products and services; or updates
              regarding your order. Smokescreen may also contact you via surveys
              to conduct research about your opinion of current services or of
              potential new services that may be offered.
            </li>
            <li>
              <strong>Analytics and product development:</strong> We and our
              service providers use Personal Information, such as your
              interactions with our products and services, to monitor and
              analyse usage of, and to improve and enhance our products,
              services, and website.
            </li>
            <li>
              <strong>Tailored user experience:</strong> To customize your
              customer experience, for example by remembering your information
              so that you will not have to re-enter it the next time you visit
              our website, to determine which products or services are the most
              popular, to deliver customized content and advertising within
              Smokescreen to those whose behaviour indicates that they are
              interested in a particular subject area.
            </li>
            <li>
              <strong>Job applications:</strong> If you apply for a job at
              Smokescreen, we may process your Personal Information to evaluate
              your application.
            </li>
            <li>
              <strong>Legal:</strong> We may use your Personal Information to
              defend our legal rights, to comply withour legal obligations and
              internal policies.
            </li>
          </ul>
          <h2>WHEN WE USE INFORMATION ABOUT YOU</h2>
          <p>
            If you are located in the European Economic Area, we only process
            your Personal Information based on a valid legal ground, including
            when:
          </p>
          <ul>
            <li>
              <strong>Consent:</strong> You have consented to the use of your
              Personal Information, for example tosend marketing communications,
              direct sales outreach, etc.
            </li>
            <li>
              <strong>Contract:</strong> We need your Personal Information to
              provide you with Smokescreen’s products and services, including
              for account registration or to respond to your inquiries.
            </li>
            <li>
              <strong>Legal obligation:</strong> We have a legal obligation to
              use your Personal Information, for example to prevent fraud.
            </li>
            <li>
              <strong>Legitimate interest:</strong> We or a third party, have a
              legitimate interest in using your Personal Information. In
              particular, we have a legitimate interest in using your Personal
              Information for product development and internal analytics
              purposes, and otherwise toimprove the safety, security, and
              performance of our products and services. We only rely on ours or
              a third party’s legitimate interests to process your Personal
              Information when these interests are not overridden by your rights
              and interests.
            </li>
          </ul>
          <h2>HOW WE DISCLOSE INFORMATION ABOUT YOU</h2>
          <p>
            Smokescreen may share Personal Information about you in the
            following circumstances:
          </p>
          <ul>
            <li>
              <strong>Smokescreen’s business customers:</strong> We may share
              Personal Information about you with our business customers when we
              process that information on their behalf as we provide them with
              our products and services.
            </li>
            <li>
              <strong>Service providers:</strong> We may disclose Personal
              Information about you to trusted partners to help perform
              statistical analysis, send you email or postal mail, provide
              customer support, arrange for deliveries or provide other services
              to Smokescreen. All such third parties are prohibited from using
              your Personal Information except to provide these services to
              Smokescreen, and they are required to maintain the confidentiality
              of your Personal Information.
            </li>
            <li>
              <strong>Merger:</strong> To a potential or actual acquirer,
              successor, or assignee as part of any reorganization, merger,
              sale, joint venture, assignment, transfer or other disposition of
              all or any portion of our business, assets or stock (including in
              bankruptcy or similar proceedings).
            </li>
            <li>
              <strong>Legal:</strong> If required to do so by law or in the good
              faith belief that such action is appropriate: (a) under applicable
              law, including laws outside your country of residence;(b) to
              comply with legal process; (c) to respond to requests from public
              and government authorities, including public and government
              authorities outside your country of residence; (d) to enforce our
              terms and conditions; (e) to protect our operations or those of
              any of our affiliates; (f) to protect our rights, privacy, safety
              or property, and/or that of our affiliates, you or others; and (g)
              to allow us to pursue available remedies or limit the damages that
              we may sustain.
            </li>
          </ul>
          <h2>SECURITY OF YOUR PERSONAL INFORMATION</h2>
          <p>
            Smokescreen secures your Personal Information by maintaining
            administrative, technical and physical safeguards that are intended
            to appropriately protect Personal Information against accidental or
            unlawful destruction, accidental loss, unauthorized alteration,
            unauthorized disclosure or access, misuse, and any other unlawful
            form of processing of the Personal Information in our possession. It
            is important that you maintain the security and control of your
            account credentials, and not share your password with anyone.
          </p>
          <h2>INTERNATIONAL DATA TRANSFERS</h2>
          <p>
            Personal Information that we collect may be stored, processed in,
            and transferred to countries other than the country where you
            initially provided the information, for example to the geographies
            in which we operate – the United States of America, United Arab
            Emirates, India, European Union – in order to enable us to use the
            information in accordance with this Policy. These countries may not
            have data protection laws equivalent to those in force in your
            country. By using our products or services, you agree to the
            transfer of your Personal Information outside of your country.
          </p>
          <h2>DATA RETENTION</h2>
          <p>
            We take measures to delete your Personal Information or keep it in a
            form that does not permit identifying you when this information is
            no longer necessary for the purposes for which we process it, unless
            we are required by law to keep this information for a longer period.
            When we process Personal Information for our own purposes, we
            determine the retention period taking into account various criteria,
            such as the type of services provided to you, the nature and length
            of our relationship with you, possible re-enrolment with our
            services, the impact on the services we provide to you if we delete
            some information from or about you, and mandatory retention periods
            provided by law and the statute of limitations.
          </p>
          <h2>YOUR RIGHTS AND CHOICES</h2>
          <ul>
            <li>
              <strong>Opt-Out & Unsubscribe:</strong> We respect your privacy
              and give you an opportunity to opt-out of receiving announcements
              of certain information. Users may opt-out of receiving any or all
              communications from Smokescreen by contacting us at{" "}
              <a href="mailto:unsubscribe@smokescreen.io">
                unsubscribe@smokescreen.io
              </a>
              . Upon request, Smokescreen will provide you with any Personal
              Information we hold about you. You may instruct us to delete your
              Personal Information at any time.
            </li>
            <li>
              <strong>European Rights:</strong> If you are located in the
              European Economic Area, you may have additional rights described
              herein above. These rights may be limited in some circumstances by
              local law requirements. You may exercise these rights by
              contacting usas indicated at the bottom of this Policy.
            </li>
            <li>
              Request access to and receive information about the Personal
              Information we maintain about you, update and correct inaccuracies
              in your Personal Information, restrict or object to the processing
              of your Personal Information, have the information anonymized or
              deleted, as appropriate, or exercise your right to data
              portability to easily transfer your Personal Information to
              another company. In addition, you may also have the right to lodge
              a complaint with a supervisory authority, including in your
              country of residence, place of work or where an incident took
              place.
            </li>
            <li>
              Withdraw any consent you previously provided to us regarding the
              processing of your Personal Information, at any time and free of
              charge. We will apply your preferences going forward and this will
              not affect the lawfulness of the processing before your consent
              withdrawal.
            </li>
          </ul>
          <h2>THIRD PARTY LINKS</h2>
          <p>
            Smokescreen encourages you to review the privacy policies of
            websites you choose to link to from Smokescreen so that you can
            understand how those websites collect, use and share your
            information. Smokescreen is not responsible for the privacy
            policies, practices, or other content on websites outside of the
            Smokescreen website.
          </p>
          <h2>CHILDREN</h2>
          <p>
            Smokescreen does not knowingly collect Personal Information from
            children under the age of fourteen. If you are under the age of
            fourteen, you must ask your parent or guardian for permission to use
            this website.
          </p>
          <h2>CHANGES TO THIS POLICY</h2>
          <p>
            Smokescreen will from time to time update this Policy to reflect
            company and customer feedback or to comply with applicable legal
            requirements. Smokescreen encourages you to periodically review this
            Policy to be informed of how Smokescreen is protecting your Personal
            Information.
          </p>
          <h2>CONTACT INFORMATION</h2>
          <p>
            Smokescreen welcomes your questions or comments regarding this
            Policy. If you have any question or would like to exercise your
            rights and choices, please contact Smokescreen at:
          </p>
          <p>
            Smokescreen 1st Floor, Kaledonia, Sahar Road, Andheri East, Mumbai
            -400069, Maharashtra, India
            <br />
            8, Federal Hill Road, Nashua, NH – 03062, USA
            <br />
            Email Address:{" "}
            <a href="mailto:info@smokescreen.io">info@smokescreen.io</a>
          </p>
          --
          <p>
            <em>Effective as of August 31st, 2020</em>
          </p>
        </div>
      </div>
    </PageLayout>
  </div>
)

export default PrivacyPolicy
